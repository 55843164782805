import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Flex, Box, Heading, Text, Button } from 'rebass'
import { LanguageContext } from './LanguageContext'
import List from './List'
import ListItem from './ListItem'
import Link from './TextLink'
import Card from './Card'

const StyledImg = styled(Img)`
  height: 100%;
`

const ApartmentPreview = ({ apartment, fluidImg }) => {
  const { translations: t } = React.useContext(LanguageContext)

  return (
    <Link to={apartment.path} title={apartment.pageTitle}>
      <Card
        bg="background"
        as="article"
        color="text.dark"
      >
        <Box
          bg="img"
          sx={{
            height: 210,
            overflow: 'hidden',
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
          }}
        >
          {
            fluidImg
              ? <StyledImg fixed={fluidImg.node.childImageSharp.fixed} />
              : null
          }
        </Box>
        <Box p={3}>
          <Heading as="h3" fontSize={3}>
            {apartment.pageTitle}
          </Heading>

          <Box
            as="hr"
            my={3}
            mx={-3}
            sx={{
              border: 0,
              borderTop: '1px solid',
              borderColor: 'divider'
            }}
          />

          <List
            mb={4}
            flexDirection="row"
          >
            <ListItem mr={2}>
              <Text color="secondary.dark" fontWeight="bold" fontSize={1}>
                {apartment.roomCount} {t.apartment.roomCount}
              </Text>
            </ListItem>
            <ListItem>
              <Text color="secondary.dark" fontWeight="bold" fontSize={1}>
                {apartment.maxPeople} {t.apartment.maxPeople}
              </Text>
            </ListItem>
          </List>

          <Flex as="footer" alignItems="center" justifyContent="space-between" mt={3}>
            <Box />
            <Button as="div" variant="buttons.brand">
              {t.apartment.details}
            </Button>
          </Flex>
        </Box>
      </Card>
    </Link>
  )
}

export default ApartmentPreview
